.container {
    display: flex;
    gap: 10px;
    min-height: 20px;
    justify-content: center;
    align-items: center;
}


.input {
    padding: 5px 10px;
}
/* Chrome, Safari, Edge, Opera */
.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.input[type=number] {
    -moz-appearance: textfield;
}

.input[pattern]:invalid{
    border-color: var(--color-red);
}


.inputAdornment {
    color: var(--color-secondary);
    font-weight: 600;
    margin-right: 10px;
    top: 50%;
    transform: translateY(-55%);
    right: 0;
    position: absolute;
}

.slider {
    margin: 0 10px;
    width: 150px;
    color: var(--color-black);
}
