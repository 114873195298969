.link {
  width: var(--width-left-sidebar);
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.link svg {
  width: 24px;
}

.link:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.active .iconWrapper {
  width: 48px;
  height: 48px;
  box-shadow: var(--shadow-accurate);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
