.wrapper {
  display: flex;
  flex-direction: column;
  width: fit-content;
}
.inputContainer {
  display: grid;
  position: relative;
}

.adornment {
  margin-right: 26px;
  top: 14px;
  right: 0;
  position: absolute;
  cursor: pointer;
}
.correct {
  margin-right: 64px;
  top: 14px;
  right: 0;
  position: absolute;
}
.search {
  width: 48px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.search.searchNotActive {
  border-radius: 50%;
  background-color: var(--color-pale-blue);
}
.search.searchToggleDisabled {
  pointer-events: none;
}

.adornment svg,
.correct svg,
.search svg {
  height: 24px;
}
.adornment svg *,
.correct svg * {
  fill: var(--color-blue);
  transition: 0.15s;
}

.adornment svg:hover * {
  fill: var(--color-primary);
}

.input::placeholder {
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  color: var(--color-secondary);
}

.input {
  box-sizing: border-box;
  border: 2px solid var(--color-blue);
  background-color: var(--color-gray);
  border-radius: 10px;
  padding: 10px;
  margin: 0;
  text-decoration: none;
  outline: none;
  width: auto;
  transition: all .5s;
}
.input.inputSearchNotActive {
  width: 28px;
  border-color: transparent;
  background-color: transparent;
  pointer-events: none;
}
.input:disabled.inputSearchNotActive {
  border: none;
}

.input:hover {
  border-color: var(--color-secondary);
}

.input:focus {
  border-color: var(--color-tertiary);
}

.input:disabled {
  border-color: var(--color-pale-blue);
  background-color: var(--color-disabled--body-gray);
  color: var(--color-disabled-gray);
}

.textAreaInput {
  resize: none;
}

.reducedWidth {
  padding-left: 10px;
  padding-right: 10px;
  width: 73px;
}

.reducedHeight {
  padding-top: 10px;
  padding-bottom: 10px;
}

.inputError, .inputError:hover, .inputError:focus {
  transition: 0.2s;
  border-color: var(--color-red);
}

.inputAdornment {
  padding-right: 72px;
}

.inputSearchActive {
  padding-right: 46px;
}

.inputCorrect {
  padding-right: 100px;
}

.description {
  color: var(--color-secondary);
  text-align: right;
  word-break: normal;
  overflow-wrap: anywhere;
}

.error {
  min-height: 24px;
  color: var(--color-red);
  word-break: normal;
  overflow-wrap: anywhere;
}

.symbol {
  cursor: default;
}

.helperTextsContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 2px;
  gap: 5px;
}

.tooltip {
  display: grid;
}
