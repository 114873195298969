.select {
  width: 150px;
}

.rounded :global(.react-select__control) {
  border-radius: 100px 40px 40px 100px;
  font-weight: 700;
  color: var(--color-primary);
}

.rounded :global(.react-select__control .react-select__single-value) {
  color: var(--color-primary);
  font-weight: 700;
}

.rounded :global(.react-select__control .react-select__single-value) {
  padding-left: 17px;
  padding-right: 0;
}
