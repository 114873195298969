.input {
  margin-bottom: 10px;
  width: 430px;
}

.description {
  display: block;
  color: var(--color-secondary);
  margin-bottom: 24px;
  width: 430px;
}

.formLabel {
  display: block;
  margin-bottom: 34px;
}

.controls {
  margin-top: 46px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icon {
  position: absolute;
  margin-top: -162px;
  width: 128px;
}
