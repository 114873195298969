.column > *,
.dynamicColumn > *,
.iconColumn > * {
  padding-right: 10px;
}
.column { width: 10%; }
.column:nth-child(1) { width: 5% }
.column:nth-child(2) { width: 20% }
.column:nth-child(3) { width: 20% }
.iconColumn { width: 5%; }
.dynamicColumn { width: 15%; }

.column .option {
  padding-top: 12px;
  box-sizing: border-box;
}

.dynamicColumn .input { width: 100%; }

.iconColumn .icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.rightArrowToLeft {
  transform: scaleX(-1);
  padding-right: 0;
  padding-left: 10px;
}

.symbol {
  line-height: 24px;
  font-weight: 600;
  color: var(--color-secondary);
}

.column .expandContainer {
  cursor: pointer;
}

.column .expandContainer svg {
  width: 24px;
  height: 24px;
  transition: .5s transform;
}

.column .expandContainer .expanded {
  transform: rotate(-180deg);
}
