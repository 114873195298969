.sliderContainer {
  position: relative;
  width: content-box;
  display: flex;
  justify-content: center;
}

.sliderContainer .slider {
  max-width: 100px;
  min-width: 0;
}

.sliderContainer .slider .status {
  letter-spacing: -0.45pt;
}

.iconArrow {
  position: absolute;
  width: 24px;
  height: 24px;
  cursor: pointer;
  z-index: 10;
}

.iconArrow > svg {
  width: 24px;
  height: 24px;
}

.iconArrow path {
  fill: var(--color-blue);
}

.prevArrow {
  left: -9px;
}
.nextArrow {
  right: -9px;
}

.prevArrow > svg {
  transform: rotate(-90deg);
}
.nextArrow > svg {
  transform: rotate(90deg);
}

.iconArrow:hover svg,
.iconArrow:hover {
  filter: invert(90%) sepia(96%) saturate(4686%) hue-rotate(172deg) brightness(93%) contrast(88%);
}

.sliderContainer:hover .iconArrow,
.iconArrow:hover {
  opacity: 1;
}
