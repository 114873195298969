.label {
  display: flex;
  margin-bottom: 6px;
  height: 24px;
}

.optional {
  margin-left: 5px;
  color: var(--color-secondary);
}

.description {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}
.description svg {
  width: 24px;
}
