.sizesContainer {
  width: 100%;
}

.sizesContainer .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sizesContainer .header .left {
  display: flex;
  align-items: center;
}

.sizesContainer .header .left .selectContainer {
  margin-left: var(--default-margin);
}

.sizesContainer .header,
.sizesContainer .sizeItemContainer:not(:last-child) {
  margin-bottom: var(--default-margin);
}
.sizeItemContainer:last-child {
  margin-bottom: 45px;
}

.sizesContainer .sizeItemContainer {
  display: flex;
  justify-content: space-between;
}

.sizesContainer .sizeItemContainer .nameInput {
  width: 40%;
}
.sizesContainer .sizeItemContainer .numberInput {
  width: 15%;
}
.sizesContainer .sizeItemContainer .numberInput > div,
.sizesContainer .sizeItemContainer .numberInput > div input {
  box-sizing: border-box;
  width: 100%;
}

.sizesContainer .sizeItemContainer .deleteBtn {
  align-self: center;
}

.sizesContainer .sizeItemContainer .input {
  padding-right: 40px;
}

.symbol {
  width: 30px;
  text-align: center;
  color: var(--color-secondary);
}
