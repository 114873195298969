.h1, .h2, .h3, .h4, .h5, .pMedium1, .pMedium2, .pMedium3, .pLight1, .pLight2, .pLight3 {
  font-family: 'Montserrat', serif;
  font-style: normal;
  color: var(--color-primary);
}

.h1 {
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;
}

.h2 {
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
}

.h3 {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
}

.h4 {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}

.h5 {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
}

.pMedium1 {
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
}

.pMedium2 {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.pMedium3 {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.pLight1 {
  font-weight: 300;
  font-size: 24px;
  line-height: 32px;
}

.pLight2 {
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
}

.pLight3 {
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
}
