.optionWrapper, .optionContent {
  display: flex;
  gap: 10px;
  align-items: center;
}

.optionIcon {
  width: 24px;
  height: 24px;
}

.optionWrapper .deleteButton {
  background-color: transparent;
  border: none;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.optionWrapper .deleteButton svg {
  width: 24px;
  height: 24px;
}

.optionWrapper .deleteButton:hover svg *, .optionWrapper .deleteButton:focus svg * {
  opacity: 0.75;
  fill: var(--color-red)
}

.select {
  min-width: 216px;
}

.optionWrapper.justifySpaceBetween, .optionContent.justifySpaceBetween {
  justify-content: space-between;
}

.optionWrapper.justifyFlexStart, .optionContent.justifyFlexStart {
  justify-content: flex-start;
}
