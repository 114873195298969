.container {
  display: flex;
  flex-direction: column;
}
.views {
  display: flex;
  flex-direction: row;
}
.left, .right {
  flex: 1;
  max-width: 50%;
}

.left {
  padding-right: 40px;
}

.view {
  background-color: var(--color-blue-alice);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 400px;
}

.viewTitle {
  width: 100%;
  text-align: center;
  padding: 12px 0;
  background-color: var(--color-pale-blue);
}

.viewContent {
  overflow: hidden;
  display: block;
  position: relative;
  height: 100%;
}

.viewNoPdf {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.viewNoPdf svg {
  height: 96px;
}
.viewNoPdf span {
  margin-top: 24px;
  color: var(--color-secondary);
}

.viewActions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 24px;

  padding: 24px 24px 12px 24px;
}

.pdfButton {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-pale-blue);

  width: 48px;
  height: 48px;
  cursor: pointer;
  border-radius: 10px;
}

.pdfButton svg {
  width: 24px;
  height: 24px;
}
