.breadcrumbs {
  display: flex;
  align-items: center;
}
.path {
  color: var(--color-secondary);
  text-transform: capitalize;
}
.path:hover {
  color: var(--color-primary);
}
.path:last-child {
  pointer-events: none;
}
.path:last-child:hover {
  color: var(--color-secondary);
}
.delimiter {
  width: 24px;
  height: 24px;
  margin: 0 4px;
}

.delimiterIcon {
  width: 24px;
  height: 24px;
  transform: rotate(90deg);
}

.delimiterIcon path {
  fill: var(--color-blue);
}
