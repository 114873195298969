.container {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.content {
  display: flex;
  flex-direction: column;
}
.pill {
  width: 146px;
  text-align: center;
  border-radius: 40px;
  margin-top: 10px;
  cursor: pointer;
  padding: 2px 0;
}
.pill:first-child {
  margin-top: 0;
}

.created { background-color: var(--color-status-created) }
.received { background-color: var(--color-status-received) }
.sendToPrinter { background-color: var(--color-status-send-to-printer) }
.inPrinting { background-color: var(--color-status-in-printing) }
.inDelivery { background-color: var(--color-status-in-delivery) }
.delivered { background-color: var(--color-status-assumed-delivered) }
.issue  { background-color: var(--color-status-issue) }
.archived  { background-color: var(--color-status-archived) }

.disabled {
  filter: grayscale(1);
}

.short {
  margin-left: 5px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
}
