.arrow:global(.slick-next), .arrow:global(.slick-prev) {
  width: 24px;
  height: 24px;
}

.arrow:global(.slick-next):before, .arrow:global(.slick-prev):before {
  content: '';
}

.arrowIcon {
  width: 24px;
  height: 24px;
}

.arrowIcon path {
  fill: var(--color-secondary);
}

.arrowIcon:hover {
  filter: invert(90%) sepia(96%) saturate(4686%) hue-rotate(172deg) brightness(93%) contrast(88%);
}

.nextArrow {
  transform: rotate(90deg);
}

.prevArrow {
  transform: rotate(-90deg);
}
