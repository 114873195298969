.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  color: var(--color-blue);
}

.container:hover {
  background-color: var(--color-gray);
}

.container svg {
  stroke: var(--color-blue);
  width: 110px;
  height: 90px;
  stroke-width: 0.75;
}

.container h3 {
  text-align: center;
  font-size: 24px;
  margin: 12px 0 12px 0;
}

.description {
  font-size: 14px;
  max-width: 80%;
  text-align: center;
}
