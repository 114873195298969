.input,
.selectContainer {
  margin-bottom: 10px;
  width: 100%;
}

body :global(.react-select__menu-portal){
  z-index: var(--index-dropdown);
}

.checkbox {
  margin-bottom: 12px;
}

.option {
  display: flex;
  align-items: center;
}

.optionIcon {
  margin-right: 8px;
  width: 24px;
  height: 24px;
}

.changePasswordButton {
  margin-top: 70px;
  width: 205px;
}

.submitBtn {
  margin-top: var(--default-margin);
}

.deleteBtn {
  margin-left: var(--default-margin);
}
