.loaderContainer {
  display: flex;
  height: 100%;
}

.container {
  display: flex;
}

.container .content {
  flex-grow: 1;
  margin-left: 46px;
}

.container .content .tabHeader {
  margin-bottom: var(--default-margin);
}

.container .content .form .description {
  min-height: 108px;
}
