.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.tabs :global(.MuiTabs-flexContainer) {
  justify-content: space-between;
}

.tab {
  width: auto !important;
  margin: 0 !important;
}
