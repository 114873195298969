.container {
    min-width: 526px;
    min-height: 440px;
}

.button {
    display: flex;
    align-items: center;
}

.button svg {
    stroke: var(--color-primary);
}
