.modalUploadList {
  max-width: 956px;
  width: 100%;
  min-width: 648px;
  display: flex;
  flex-direction: column;
  text-align: center;
  position: relative;
}

.tooltip {
  white-space: pre-line
}

.footer {
  margin-top: 34px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.column {
  text-align: left;
  padding: 0 10px;
  width: 25%;
  display: flex;
  align-items: center;
}

.columnText {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}

.groupHeaderCell {
  display: flex;
  cursor: pointer;
}
.groupHeaderCell svg {
  width: 24px;
  margin-right: 14px;
}
.groupHeaderCell .expanded {
  transform: rotate(-180deg);
}

.loader {
  position: absolute;
  height: calc(100% + 20px);
  width: 100%;
}

.sampleImage {
  height: 40px;
}
.sampleTextImage {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.line {
  display: flex;
  flex-direction: column;
}

.description {
  color: var(--color-secondary);
}
