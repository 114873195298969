.fullName {
  max-width: 100%;
  text-align: center;
  word-break: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

.userInfoTable {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 18px;
}

.userInfoRow {
  display: flex;
  justify-content: space-between;
}

.userInfoRow > div {
  display: flex;
  align-items: center;
}

.tableHeaderCell {
  color: var(--color-secondary);
  padding-right: 20px;
}

.textAlignRight {
  text-align: right;
  word-break: break-word;
}

.langIcon {
  margin-right: 8px;
  width: 16px;
  height: 16px;
}
