.statusLabel {
  width: 120px;
  height: 24px;
  border-radius: 20px;
  color: var(--color-white);
  display: flex;
  justify-content: center;
  align-items: center;
}

.centerContent {
  padding-left: 5px;
}

.statusLabel.published {
  background-color: var(--color-tertiary);
}

.statusLabel.draft {
  background-color: var(--color-blue);
}
