.footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.cancelWithStatusSelect {
  display: flex;
  gap: 24px;
}
