.container {
  display: flex;
}

.container > *:first-child {
  min-width: 250px;
}

.tab {
  padding-top: 6px;
  padding-bottom: 70px;
  padding-left: 22px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.tab > *:first-child {
  margin-bottom: 24px;
}

.loaderContainer {
  display: flex;
  height: 100%;
}
