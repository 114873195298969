.gridViewContainer {
  margin-bottom: 24px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  grid-gap: var(--default-margin);
}

.gridViewContainer .articleItem {
  padding: 11px 24px 26px 24px;
  border-radius: 10px;
  background: var(--gradient-sidebar);
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  overflow: hidden; /* for slider */
  cursor: pointer;
}

.gridViewContainer .itemForCustomer {
  padding-top: 61px;
}

.gridViewContainer .articleItem .headerControls,
.gridViewContainer .articleItem .controls,
.gridViewContainer .articleItem .end>div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.controls {
  margin-top: 5px;
}

.end>div {
  margin-top: 20px;
  gap: 24px;
}

.articleTitle {
  min-height: 48px;
  max-height: 48px;
  display: flex;
  align-items: center;
  overflow: hidden;
  word-break: normal;
  overflow-wrap: anywhere;
}

.quantities {
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
