.shadow {
  box-shadow: var(--shadow-accurate);
}

.hide {
  display: none;
}

.error {
  outline: 5px solid var(--color-alert-yellow);
}
