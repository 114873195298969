.container {
  display: flex;
  flex-direction: column;
  padding-bottom: var(--padding-bottom-page);
}

.container .header {
  margin-bottom: 32px;
  display: flex;
  justify-content: space-between;
}

.container .header .right {
  display: flex;
}

.container .header .right .search {
  margin-right: var(--default-margin);
}

.container .content,
.container .content table {
  width: 100%;
}

.container .pagination {
  align-self: flex-end;
}
