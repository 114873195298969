.loaderContainer {
  height: 100%;
  display: flex;
}

.container {
  height: 80%;
  display: flex;
  flex-direction: column;
}

.container .filtersBlock {
  margin-bottom: var(--default-margin);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.container .filtersBlock .sort {
  cursor: pointer;
}

.container .filtersBlock .sort svg {
  width: 24px;
  height: 24px;
}

.container .items {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(45%, 1fr));
  grid-gap: var(--default-margin);
}
