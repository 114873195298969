.container {
  margin-top: 55px;
  display: flex;
}

.left, .right {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.left {
  padding-right: 40px;
}

.label {
  margin-bottom: 30px;
}

.pricing {
  background-color: var(--color-gray);
  border-radius: 10px;
  padding: 24px 24px 0 24px;
}

.select {}
