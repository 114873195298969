.container {
  display: flex;
  height: 100%;
}

.scrollableX {
  overflow-x: auto;
  width: calc(100vw - var(--width-left-sidebar));
}
.scrollableXWithSidebar {
  width: calc(100vw - var(--width-right-sidebar) - var(--width-left-sidebar));
}

.innerContainer {
  width: 100%;
  padding: 24px 48px;
}

.withFooter {
  padding-bottom: var(--height-footer);
}

.breadcrumbs {
  display: block;
  margin-bottom: 50px;
}

.title {
  display: block;
  margin-bottom: 50px;
}

.withBreadcrumbs {
  margin-bottom: 14px;
}

.footer {
  z-index: var(--index-sidebar);
  position: fixed;
  bottom: 0;
  width: calc(100% - var(--width-left-sidebar));
  display: flex;
  background-color: var(--color-pale-blue);
  height: var(--height-footer);
  padding: 20px 48px;
  align-items: center;
}

.container .sideBarWithFooter {
  height: calc(100% - var(--height-footer));
  scrollbar-gutter: stable;
}
