.description {
  display: block;
  color: var(--color-secondary);
  margin-bottom: 24px;
  width: 430px;
}

.formLabel {
  display: block;
  margin-bottom: 34px;
}

.icon {
  position: absolute;
  margin-top: -162px;
  width: 128px;
}

.link {
  color: var(--color-tertiary);
}
