.container {
  display: flex;
  height: 100%;
}

.leftSide {
  min-width: 300px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  flex: 1;
  background-color: var(--color-primary);
  padding: 40px 50px;
}

.logoContainer {
  flex: 2;
  width: 100%;
  display: flex;
}

.logoContainer svg {
  width: auto;
  height: 32px;
}

.imageContainer {
  flex: 3;
}

.banner {
  flex: 2
}

.rightSide {
  flex: 2;
  display: flex;
  flex-direction: column;
}

.link, .link:hover {
  color: var(--color-secondary)
}

.linkLabel {
  color: var(--color-primary);
  cursor: default;
}

.wrapperContainer {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.navigate {
  top: 48px;
  right: 48px;
  position: absolute;
}

.innerContainer {
  max-width: 430px;
}
