.paper {
    padding: 5px;
    overflow: visible;
}

.paper .picker {
    width: 100%;
}

.colorContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 300px;
}

.colorGroup {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 0 8px 0 8px;
}

.colorGroup .colorTitle {
    font-size: 14px;
    color: var(--color-primary);
    font-weight: 700;
}

.colorGroup .colorList {
    display: inline-flex;
    flex-wrap: wrap;
    gap: 10px;
}

.colorGroup .colorList .button {
    cursor: pointer;
    width: 32px;
    height: 32px;
    border: none;
    border-radius: 50%;

    outline: 1px solid rgba(0, 0, 0, 0.15);
    outline-offset: -1px;
}

.colorGroup .colorList .button svg {
    width: 18px;
    height: 18px;
    opacity: 0.35;
}

.colorGroup .colorList .button.active svg {
    opacity: 0.5;
}

.colorGroup .colorList .button:hover, .colorGroup .colorList .button:focus, .colorGroup .colorList .button.active {
    outline: 2px solid var(--color-tertiary);
}
