.tableContainer {
  min-width: 964px;
  max-width: 80vw;
}

.paperColumn {
  width: 280px;
}

.qtyColumn {
  width: 120px;
  padding-left: 24px;
}

.arrowColumn {
  width: 24px;
}

.arrow {
  width: 18px;
  height: 12px;
  cursor: pointer;
}

.mirrorArrow {
  transform: scaleX(-1);
}
