.loaderContainer {
  z-index: var(--index-alert);
  top:0;
  left: 0;
  background: rgba(0,0,0,0.02);
  height: 100vh;
  overflow-y: hidden;
  width: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.controls {
  display: flex;
  margin-bottom: 24px;
  justify-content: space-between;
}

.controlItems {
  max-width: 300px;
  display: flex;
  align-items: center;
}

.itemsCount {
  margin-left: 12px;
  color: var(--color-secondary)
}


