.container {
  display: flex;
  flex-direction: column;
  padding-bottom: var(--padding-bottom-page);
}

.container .header {
  display: flex;
  justify-content: space-between;
}

.container .header .left .select {
  width: 100px;
}

.container .header .right {
  width: 100%;
  margin-bottom: 32px;
  display: flex;
  justify-content: flex-end;
}

.container .header .right .search {
  margin-right: var(--default-margin);
}

.container .pagination {
  align-self: flex-end;
}

.column:not(:last-child) {
  padding-right: 5px;
}
.column * {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.column:nth-child(1) {
  width: 19%;
}
.column:nth-child(2) {
  width: 29%;
}
.column:nth-child(3) {
  width: 29%;
}
.column:nth-child(4) {
  width: 19%;
}
.column:nth-child(5) {
  width: 75px;
}

.column .columnHeadFilter {
  display: flex;
  justify-content: space-between;
}

.column .columnHeadFilter svg {
  min-width: 24px;
  max-width: 24px;
  height: 24px;
  margin-right: 24px;
}

.displayFlex {
  display: flex;
}

.centeredCell {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
