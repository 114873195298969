.container {
    display: flex;
    flex-direction: row;
    gap: 12px;
    justify-content: space-between;
    align-items: center;
    height: 32px;
}

.container * {
    transition: all .5s;
}

.container.active input {
    color: var(--color-tertiary);
}

.container.active svg * {
    fill: var(--color-tertiary);
}

.container.error input {
    color: var(--color-red);
}

.container.error svg * {
    fill: var(--color-red);
}

.icon {
    cursor: pointer;
    flex-shrink: 0;
}

.iconDisabled {
    cursor: not-allowed;
}
