.form {
  width: 744px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.form .input {
  flex-basis: 47%;
}
