.input {
  margin-bottom: 10px;
  width: 430px;
}

.formLabel {
  display: block;
  margin-bottom: 34px;
}

.controls {
  margin-top: 46px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icon {
  background-color: var(--color-pale-blue);
  border-radius: 50%;
  position: absolute;
  margin-top: -162px;
  width: 128px;
}
