
@font-face {
  font-family: "Verdana";
  font-weight: 400;
  src: url("../fonts/verdana.ttf");
}

@font-face {
  font-family: "Arial, sans-serif";
  font-weight: 400;
  src: url("../fonts/arial.ttf");
}

/* For fabric.js needs to define fonts by their names only */
@font-face {
  font-family: "Arial";
  font-weight: 400;
  src: url("../fonts/arial.ttf");
}

@font-face {
  font-family: "Helvetica";
  font-weight: 400;
  src: url("../fonts/helvetica.ttf");
}

@font-face {
  font-family: "Trebuchet MS";
  font-weight: 400;
  src: url("../fonts/trebuc.ttf");
}

@font-face {
  font-family: "Gill Sans";
  font-weight: 400;
  src: url("../fonts/gillsans.ttf");
}

@font-face {
  font-family: "Noto Sans";
  font-weight: 400;
  src: url("../fonts/notosans.ttf");
}

@font-face {
  font-family: "Courier New";
  font-weight: 400;
  src: url("../fonts/couriernew.ttf");
}

@font-face {
  font-family: "Arial Narrow";
  font-weight: 400;
  src: url("../fonts/arialnarrow.ttf");
}

@font-face {
  font-family: "Montserrat";
  font-weight: 400;
  src: url("../fonts/Montserrat-Regular.ttf");
}

@font-face {
  font-family: "Montserrat";
  font-weight: 500;
  src: url("../fonts/Montserrat-Medium.ttf");
}

@font-face {
  font-family: "Montserrat";
  font-weight: 300;
  src: url("../fonts/Montserrat-Light.ttf");
}

@font-face {
  font-family: "Montserrat";
  font-weight: 700;
  src: url("../fonts/Montserrat-Bold.ttf");
}
