.container {
  display: flex;
  flex-direction: column;
}

.row {
  width: 100%;
  background: var(--color-pale-blue);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  border-radius: 10px;
  margin-top: 24px;
}

.row > div {
  display: flex;
  align-items: center;
}

.text {
  margin-left: 5px;
}

.row svg {
  width: 24px;
}
