.productType {
  display: flex;
  align-items: center;
}

.productType .icon {
  width: 24px;
  height: 20px;
  margin-right: 10px;
}

.productTypeLabel {
  white-space: nowrap;
}
