.tabs {
  width: 100%;
}
.tabs button {
  margin-bottom: 2px !important;
}

.items {
  margin-top: 24px;
  padding-right: 24px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.item {
  width: 100%;
  margin-top: 24px;
}

.inputImage {
  display: flex;
  flex-direction: column;
}
.inputImage img {
  max-width: 100%;
  max-height: 100%;
}

.label {
  align-items: center;
  height: auto;
  min-height: 24px;
  word-break: break-word;
  hyphens: auto;
}

.label>span {
  display: flex;
  word-break: normal;
}

.controls {
  display: flex;
  align-items: center;
}
.controls > * {
  margin-right: 15px;
}

.controls input {
  display: none;
}

.imageContainer {
  width: 100px;
  height: 100px;
  min-width: 100px;
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  background: var(--color-pale-blue);
  box-shadow: var(--shadow-accurate);
}
