.sliderContainer {
  margin-bottom: 25px; /* because slider dots are rendered with absolute position below slider content */
}

.sliderItem {
  height: 180px;
  width: 180px;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.sliderItem img {
  max-width: 180px;
  max-height: 180px;
}

.dots {
  margin: 0;
}
