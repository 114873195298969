.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.container img {
    max-width: 90%;
    height: auto;
    box-shadow: var(--shadow-accurate);
}
