.sliderContainer {
  width: 80%;
  height: 100%;
  display: flex;
}

.sliderContainer :global(.slick-list) {
  width: 100%;
}
.sliderContainer :global(.slick-track) {
  height: 100%;
}
.sliderContainer :global(.slick-slide) > div {
  height: 100%;
}

.dots {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.side {
  color: var(--color-primary);
  margin-bottom: -10px;
}
.sliderContainer :global(.slick-dots) {
  bottom: -38px;
}

.dots div li,
.dots div li button,
.dots div li button::before {
  padding: 0;
  width: 10px;
  height: 10px;
}
.dots div li {
  margin: 0 3px;
}
.sliderContainer :global(.slick-arrow)::before {
  content: none;
}
.arrow {
  height: 20px;
  width: 20px;
}
.arrowDisabled {
  cursor: default;
  opacity: 0.3;
}
