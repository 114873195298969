.title {
  margin-bottom: var(--default-margin);
}

.modalContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container {
  width: 744px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(45%, 1fr));
  grid-gap: var(--default-margin);
}

.content {
  margin-bottom: var(--default-margin);
}

.search {
  position: absolute;
  right: 40px;
  top: 80px;
}
