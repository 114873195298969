.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.input {
  margin-top: 20px;
  width: 100%;
}

.modal {
  min-width: 500px;
}
