.customLabel {
  margin: 6px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.customLabel .icon {
  width: 18px;
  height: 18px;
  cursor: pointer;
}