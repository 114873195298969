.commentContainer {
  composes: commentContainer from '../../TabProductSetup.module.css';
}

.negativeMargin {
  margin-bottom: -74px;
}

.input {
  composes: input from '../../TabProductSetup.module.css';
}

.paperCheckbox {
  margin-bottom: 24px;
  width: 48.5%;
}

.qtiesSelectContainer {
  margin-bottom: 24px;
}

.qtySelect :global(.react-select__control) {
  min-height: 128px;
  align-items: flex-start;
}

.unselectedQties :global(.react-select__control) {
  background-color: transparent;
  border-color: transparent;
}

.unselectedQties :global(.react-select__control):hover {
  border-color: transparent;
}

.unselectedQties :global(.react-select__control .react-select__multi-value) {
  background-color: var(--color-primary);
}

.unselectedQties :global(.react-select__control .react-select__multi-value__label) {
  color: var(--color-white);
}

.unselectedQties :global(.react-select__control .react-select__multi-value__remove) svg * {
  fill: var(--color-white);
}
