.container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 144px;
  padding: 26px 18px;
  border: 2px solid var(--color-blue);
  border-left-width: 8px;
  border-radius: 10px;
  cursor: pointer;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.container.active,
.container .titleContainer .circle.circleActive {
  border-color: var(--color-tertiary);
}

.container.disabled {
  border-color: var(--color-disabled-gray);
  pointer-events: none;
}

.container .titleContainer {
  display: flex;
}

.container .titleContainer .circle {
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 10px;
  margin-top: 2px;
  border: 1px solid var(--color-blue);
}

.container .titleContainer .circle.circleActive {
  border-width: 7px;
}

.container .titleContainer .title {
  flex-basis: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.container .descriptionContainer {
  font-size: 14px;
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}
