.button {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    height: 20px;
    width: 20px;
    padding: 0;
    margin: 0;
    background: none;
    border: 2px solid transparent;
}

.button svg {
    width: 16px;
    height: 16px;
}

.button:disabled svg * {
    stroke: none;
}
