.container {
  display: flex;
  width: 100%;
  padding-bottom: var(--padding-bottom-page);
}
.container > div:first-child {
  width: 260px;
}
.container > div:last-child {
  flex: 1;
  padding-left: 32px;
}

.column .expandContainer {
  cursor: pointer;
  display: flex;
  overflow: hidden;
  font-weight: 500;
  font-size: 12px;
}

.column .expandContainer svg {
  width: 24px;
  height: 24px;
  transition: .5s transform;
}

.column .expandContainer .expanded {
  transform: rotate(-180deg);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.title {
  align-items: center;
  display: flex;
  flex-direction: row-reverse;
  gap: 24px;
}

.select {
  width: 100px;
}

.right {
  display: flex;
  gap: 12px;
}
.search {
  margin-right: 24px;
}

.search input {
  width: 200px;
}

.table {
  margin-top: 32px;
}

.noContent {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 400px;
}

.loader {
  min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.pagination {
  display: flex;
  justify-content: right;
}

.column {
  display: flex;
  align-items: center;
  overflow-x: hidden;
  padding-right: 5px;
}
.column * {
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.column:nth-child(1) { width: auto; min-width: 4% !important; }
.column:nth-child(2) { width: 18% !important; }
.column:nth-child(3) { width: 18% !important; }
.column:nth-child(4) { width: 18% !important; cursor: pointer; }
.column:nth-child(5) { width: 18% !important; cursor: pointer; }
.column:nth-child(6) { width: 18% !important; cursor: pointer; }
.column:nth-child(7) { width: 10% !important; padding: 0; }
.column:nth-child(8) > * { padding-right: 0; }

.managerViewColumn:nth-child(1) { width: 18% !important; }
.managerViewColumn:nth-child(2) { width: 18% !important; }
.managerViewColumn:nth-child(3) { width: 15% !important; }
.managerViewColumn:nth-child(4) { width: 18% !important; }
.managerViewColumn:nth-child(5) { width: 25% !important; cursor: pointer; }
.managerViewColumn:nth-child(6) { width: 6% !important; padding: 0; }
.managerViewColumn:nth-child(6) > * { padding-right: 0; }

.customerViewColumn:nth-child(1) { width: 25% !important; }
.customerViewColumn:nth-child(2) { width: 25% !important; }
.customerViewColumn:nth-child(3) { width: 19% !important; }
.customerViewColumn:nth-child(4) { width: 25% !important; padding: 0; }
.customerViewColumn:nth-child(5) { width: 6% !important; padding: 0; }
.customerViewColumn:nth-child(5) > * { padding-right: 0; }

.column:last-child {
  display: flex;
  justify-content: flex-end;
}
.column:last-child svg {
  width: 32px;
}

.columnName {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-right: 5px;
}

.columnName svg {
  height: 24px;
  min-width: 24px;
  max-width: 24px;
  margin-right: 24px;
}
