.iconInfo {
  height: 24px;
}
.iconInfo:hover * {
  filter: brightness(0.5);
}

.iconContainer {
  display: flex;
  align-items: center;
}
