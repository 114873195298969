.controls {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: auto;
}

.counter {
    display: flex;
    justify-content: center;
    align-items: center;
}
