.container {
  box-sizing: border-box;
  padding-top: 100px;
  display: flex;
  flex-direction: column;
}

.person {
  position: relative;
  padding: 40px 24px 24px;
  display: flex;
  flex-direction: column;
  background-color: var(--color-gray);
  border-radius: 10px;
  align-items: center;
}

.defaultAvatar {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-gray);
}
.defaultAvatar svg {
  width: 48px;
}

.avatar {
  cursor: pointer;
  top: -100px;
  position: absolute;
  box-sizing: border-box;
  width: 124px;
  height: 124px;
  overflow: hidden;
  border-radius: 50%;
  background: var(--gradient-icon-border);
  padding: 8px;
}

.avatar.blocked {
  background: var(--gradient-icon-border-red);
}

.avatar > * {
  border-radius: 50%;
  width: 108px;
  height: 108px;
  object-fit: cover;
}

.dots {
  cursor: pointer;
  position: absolute;
  right: 12px;
  top: 12px;
  width: 24px;
  height: 24px;
}
.dots * {
  fill: var(--color-blue);
}
.actions {
  min-width: 212px;
  padding: 12px;
  display: flex;
  flex-direction: column;
}
.actions button {
  text-align: left;
}







.none {
  display: none;
}

.nonEditable {
  cursor: default;
}
