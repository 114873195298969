.overlay {
  display: flex;
  justify-content: center;
  position: fixed;
  inset: 0;
  background-color: var(--color-primary-opacity);
  overflow-y: auto;
  max-height: 100vh;
  z-index: var(--index-modal);
}

.modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 145px;
  position: absolute;
  background: var(--color-white);
  border-radius: 20px;
  outline: none;
  padding: 48px;
  min-width: 400px;
}

.iconClose {
  position: relative;
  top: -6px;
}

.iconClose svg {
  cursor: pointer;
  width: 25px;
  height: 25px;
}
.iconClose svg:hover * {
  fill: var(--color-black);
}

.wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.header {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
}

.header > div {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 152px;
  height: 152px;
  border-radius: 50%;
  background-color: var(--color-white);
  top: 0;
  margin-top: -75px;
  z-index: var(--index-modal-dropdown);
}
.header > div > svg {
  height: 128px;
  width: 128px;
}

.header > div + span {
  padding-top: 48px;
}

.controls {
  width: 100%;
  margin-top: 40px;
  display: flex;
  flex-flow: row-reverse;
  justify-content: space-between;
}
