.container {
  display: flex;
  align-items: flex-start;
  width: 100%;
}

.container.noDeletion {
  justify-content: space-between;
}

.container > * {
  margin-bottom: var(--default-margin);
}
.container > *:not(:last-child) {
  margin-right: var(--default-margin);
}

.container .multiSelectContainer,
.container .commentContainer {
  flex-basis: 45%;
}

.container .commentContainer .input {
  width: 100%;
}

.container .deleteBtn {
  align-self: flex-end;
}

.container .deleteBtn .icon {
  width: 24px;
  height: 24px;
}

.deleteBtnContainer {
  margin: 35px 0 0;
}
