.root {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 100px;
}

.icon {
  width: 250px;
  height: 200px;
}

.label {
  color: var(--color-primary);
  margin-bottom: 12px;
  margin-top: 24px;
}

.description {
  color: var(--color-primary);
}

.children {
  padding-top: 20px;
}