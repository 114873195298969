.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 49%;
    gap: 32px;
    color: var(--color-secondary);
}

.side {
    width: 100%;
    border-radius: 10px;
    background-color: var(--color-blue-alice);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.viewport {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 332px;
}

/* UploadArea */
.viewport>section {
    width: 100%;
}

.thumbnail {
    max-width: calc(100% - 20px);
    max-height: calc(100% - 20px);
    object-fit: contain;
    box-shadow: var(--shadow-accurate);
    margin: 10px;
}

.title {
    position: relative;
    width: 100%;
    height: 48px;

    font-size: 16px;
    font-weight: bold;
    color: var(--color-primary);
    border-radius: 0 0 10px 10px;
    background-color: var(--color-pale-blue);

    display: flex;
    justify-content: center;
    align-items: center;

    z-index: 1;
}

.title .reprocess {
    display: flex;
    justify-content: center;
    gap: 4px;
    align-items: center;
    position: absolute;
    right: 0;
}

.contentContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.contentSubtitle {
    font-weight: 700;
}

.contentList {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.actions {
    position: absolute;
    top: 24px;
    right: 24px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
