.modalContainer {
  width: 956px;
}

.column {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.column > * { padding-right: 7px }

.column:nth-child(1) { width: 5%; }
.column:nth-child(2) { width: 15%; }
.column:nth-child(3) { width: 20%; }
.column:nth-child(4) { width: 35%; }
.column:nth-child(5) { width: 25%; }

.column .expandContainer {
  cursor: pointer;
}

.column .expandContainer svg {
  width: 24px;
  height: 24px;
  transition: .5s transform;
}

.column .expandContainer .expanded {
  transform: rotate(-180deg);
}

.column .fieldTitleType {
  color: var(--color-secondary);
}

.column .select {
  width: 90%;
}

.column .sample {
  word-break: break-all;
  text-overflow: ellipsis;
  overflow: hidden;
}
