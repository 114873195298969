.container {
  display: flex;
  flex-direction: column;
  gap: 15px;

  font-family: 'Montserrat', sans-serif;
  text-align: center;
  color: #2C3E66;
}

.container h2 {
  font-size: 16px;
  font-weight: 700;
}
