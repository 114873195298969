.form {
    width: 480px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
    padding-bottom: 24px;
}

.form .input {
    flex-basis: 47%;
}

