.popover {}
.popover div.tooltip {
  background-color: var(--color-black);
  border-radius: 4px;
  padding: 5px 10px;
  /* pMedium3 */
  font-family: 'Montserrat', serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: var(--color-white);
  white-space: pre-line;
}
.popover[data-popper-placement*="bottom"] div.tooltip {
  margin-top: 12px;
}
.popover[data-popper-placement*="top"] div.tooltip {
  margin-bottom: 12px;
}
.popover[data-popper-placement*="left"] div.tooltip {
  margin-right: 12px;
}
.popover[data-popper-placement*="right"] div.tooltip {
  margin-left: 12px;
}
.popover span.arrow {
  color: var(--color-black);
}
