@import "./fonts.css";

* {
  box-sizing: border-box;
}
html, body, #root {
  height: 100%;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Montserrat', serif;
  font-style: normal;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scrollbar-color: var(--color-secondary) var(--color-white);
  scrollbar-width: thin;
}

a, a:hover, a:focus, a:active {
  text-decoration: none;
  color: inherit;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  /* color */
  --color-primary: #2C3E66;
  --color-primary-opacity: #2C3E6680;
  --color-secondary: #A6CBDF;
  --color-secondary-hover: #8DC8E8;
  --color-secondary-hover-dark: #64AAD0;
  --color-primary-disabled: #8597AF;
  --color-blue-disabled: #DDEFF7;
  --color-tertiary: #00C0FF;
  --color-blue: #BCDEF0;
  --color-blue-alice: #F7FCFE;
  --color-pale-blue: #EAF7FD;
  --color-dark-blue: #262F56;
  --color-gray: #F2FAFF;
  --color-disabled-gray: #959FB3;
  --color-disabled--body-gray: #FBFDFF;
  --color-white: #FFF;
  --color-black: #000;
  --color-red: #FF2800;
  --color-alert-red: #FFD4CC;
  --color-alert-yellow: #FCE9B1;
  --color-alert-blue: #BDECFD;
  --color-green: #b6f1c4;
  --color-green-bold: #0b932a;

  --color-status-created: #C4C3F9;
  --color-status-received: #BCDEF0;
  --color-status-send-to-printer: #BCF0D4;
  --color-status-in-printing: #87c59c;
  --color-status-in-delivery: #6fa688;
  --color-status-assumed-delivered: #C7EEB6;
  --color-status-issue: #FFCCCC;
  --color-status-archived: #FFD6B9;

  --gradient-sidebar: linear-gradient(345.33deg, #A8D6EF 0%, #CDECFD 27.17%, #E9F7FF 67.19%, #F1F7FA 100%);
  --gradient-icon-border: linear-gradient(135deg, #BFEFCA 25%, #99DAA8 100%);
  --gradient-icon-border-red: linear-gradient(135deg, #fcd4d4 25%, #e5a6a6 100%);

  /* shadow */
  --shadow-diffused: 0 50px 80px rgba(44, 62, 102, 0.12);
  --shadow-accurate: -4px -4px 20px rgba(255, 255, 255, 0.15), 4px 4px 10px rgba(0, 0, 0, 0.25);

  /* z-index */
  --index-alert: 1000;
  --index-popup-dropdown: 150;
  --index-popup: 100;
  --index-modal-dropdown: 75;
  --index-modal: 50;
  --index-dropdown: 25;
  --index-sidebar: 10;
  --index-one: 1;

  /* width */
  --width-right-sidebar: 400px;
  --width-left-sidebar: 96px;

  /* height */
  --height-footer: 88px;

  /* padding */
  --padding-form-footer: 48px;
  --padding-bottom-page: 34px;

  /* margin */
  --form-input-margin: 34px;
  --default-margin: 24px;
}

::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}
/* Track */
::-webkit-scrollbar-track {
  background: none;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--color-secondary);
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--color-secondary-hover-dark);
}
