.input {
  width: 100%;
  background-color: transparent;
  border-color: transparent;
  cursor: pointer;
  font-weight: 600;
  padding: 5px 10px;
}

.input:focus {
  cursor: text;
  border-color: var(--color-blue);
  background-color: var(--color-white);
  border-radius: 10px;
}

input.invalid:focus {
  border-color: var(--color-red);
}
