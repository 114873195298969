.container {
  width: 872px;
}

.commonManagerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.commonManagersSelect {
  min-width: 223px;
}

.table {
  margin-top: 24px;
}
