.container {
  display: flex;
  height: 100%;
}

.sidebar {
  height: 100%;
  z-index: var(--index-sidebar);
  position: fixed;
  background-color: var(--color-primary);
  width: var(--width-left-sidebar);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content {
  margin-left: var(--width-left-sidebar);
  flex: 1;
}

.logoContainer {
  margin-top: 30px;
}

.logo {
  width: 32px;
  height: 32px;
}

.itemsContainer, .itemsTop, .itemsBottom {
  display: flex;
  flex-direction: column;
}

.itemsContainer {
  padding-top: 90px;
  height: 100%;
  justify-content: space-between;
}

.loader {
  display: flex;
  height: 100%;
}
