.dropdownArea {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 190px;
  background-color: var(--color-blue-alice);
  cursor: pointer;
}
.dropdownArea > svg {
  width: 64px;
}
.dropdownArea > span {
  margin-top: 4px;
  color: var(--color-blue);
}

.dragActive {
  border: 1px dashed var(--color-secondary);
}

.bottomControls {
  margin-top: 34px;
  display: flex;
  width: 100%;
  cursor: unset;
}

.controls {
  display: flex;
  align-items: center;
}

.controls > span {
  color: var(--color-secondary);
}
.controls > svg {
  width: 24px;
  margin-right: 8px;
}
