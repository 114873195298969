.column > * { padding-right: 5px }
.column:nth-child(1) { width: 5% }
.column:nth-child(2) { width: 25% }
.column:nth-child(3) { width: 30% }
.column:nth-child(4) { width: 15% }
.column:nth-child(5) { width: 35% }

.column .expandContainer {
  cursor: pointer;
}

.column .expandContainer svg {
  width: 24px;
  height: 24px;
  transition: .5s transform;
}

.column .expandContainer .expanded {
  transform: rotate(-180deg);
}

.symbol {
  line-height: 24px;
  font-weight: 600;
  color: var(--color-secondary);
}

.row {
  padding-top: 12px;
  box-sizing: border-box;
}

.input {
  width: 100%;
}
