.container {
  display: flex;
  flex-direction: column;
  padding-bottom: var(--padding-bottom-page);
}

.container .header {
  display: flex;
  justify-content: space-between;
}

.container .select {
  width: 100px;
}

.container .right {
  display: flex;
}
.container .search {
  margin-right: 24px;
}

.container .search input {
  width: 200px;
}

.container .table {
  margin-top: 32px;
}

.container .noContent {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 400px;
}

.container .loader {
  min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.container .customersControls {
  width: 100%;
  margin-bottom: 32px;
  display: flex;
  justify-content: flex-end;
}

.container .customersControls .addButton {
  margin-left: 24px;
}

.container .pagination {
  display: flex;
  justify-content: right;
}

/* table cells */
.container .customerNameCell {
  display: flex;
  flex-direction: column;
}

.container .sliderItem {
  text-align: center;
}

.container .sliderItem .status {
  letter-spacing: -0.45pt;
}

.container .sliderItem svg {
  width: 16px;
  height: 16px;
}

.container .customerBlocked {
  background-color: var(--color-alert-red);
}

.container .customerNotBlocked {
  background-color: var(--color-green);
}

.column > * {
  padding-right: 5px;
}
.column:nth-child(1) { width: 20% !important; }
.column:nth-child(2) { width: 22% !important; }
.column:nth-child(3) { width: 18% !important; padding-left: 15px; }
.column:nth-child(4) { width: 13% !important; padding-left: 15px; }
.column:nth-child(5) { width: 17% !important; }
.column:nth-child(6) { width: 10% !important; }
.column:nth-child(6) > * { padding-right: 0; }

.managerViewColumn:nth-child(1) { width: 30% !important; }
.managerViewColumn:nth-child(2) { width: 30% !important; }
.managerViewColumn:nth-child(3) { width: 13% !important; padding-left: 15px; }
.managerViewColumn:nth-child(4) { width: 17% !important; }
.managerViewColumn:nth-child(5) { width: 10% !important; }
.managerViewColumn:nth-child(5) > * { padding-right: 0; }

.column > div:not(.cellAction) {
  height: 100%
}

.columnName, .columnAction {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.displayFlex {
  display: flex;
}

.cellCentered {
  display: flex;
  justify-content: center;
}

.columnName svg {
  height: 24px;
  min-width: 24px;
  max-width: 24px;
  margin-right: 24px;
}
.cellAction svg,
.columnAction svg {
  height: 24px;
  min-width: 24px;
  max-width: 24px;
}

.cellAction {
  cursor: pointer;
  background-color: var(--color-pale-blue);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
}
.cellAction:hover,
.cellAction:hover * {
  filter: brightness(0.95);
}

.columnNames * {
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
}
.columnNames {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.cellAction {
  background-color: var(--color-green);
}
.cellAction svg * {
  fill: var(--color-green-bold);
}

.locked {
  background-color: var(--color-alert-red);
}
.locked svg * {
  fill: var(--color-red);
}
