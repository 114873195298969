.radioGroup {
  gap: 10px;
}

.icon {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background-color: var(--color-pale-blue);
}

.checkedIcon {
  background-color: var(--color-primary);
  background-image: linear-gradient(180deg, hsla(0, 0%, 100%, .1), hsla(0, 0%, 100%, 0));
}

.checkedIcon::before {
  display: block;
  width: 20px;
  height: 20px;
  background-image: radial-gradient(#fff, #fff 15%, transparent 25%);
  content: "";
}
