.select :global(.react-select__value-container) {
  padding-left: 2px;
}
.select :global(.react-select__control) {
  background-color: var(--color-pale-blue);
  border: 2px solid var(--color-blue);
  border-radius: 10px;
  outline: none;
  padding-left: 0;
  cursor: pointer;
}

.select:not(.onlyChoosable) :global(.react-select__control--is-focused) :global(.react-select__value-container) {
  cursor: text;
}
.select :global(.react-select__control):hover {
  border-color: var(--color-secondary);
}
.select :global(.react-select__control--is-focused) {
  box-shadow: none;
  border-color: var(--color-tertiary);
}
.select :global(.react-select__control .react-select__dropdown-indicator) {
  transition: 0.15s;
}
.select :global(.react-select__control--menu-is-open .react-select__dropdown-indicator) {
  transform: rotate(180deg);
}
.select :global(.react-select__control .react-select__dropdown-indicator *) {
  fill: var(--color-primary);
}
.select :global(.react-select__control--is-disabled .react-select__indicators *) {
  fill: var(--color-primary-disabled);
}
.select :global(.react-select__control--is-disabled) {
  border-color: var(--color-blue-disabled);
}

.closeIcon svg {
  width: 14px;
}
.closeIcon svg:hover {
  transform: scale(1.05);
}

.placeholder {
  color: var(--color-secondary) !important;
  padding: 8px 10px;
}
.select :global(.react-select__control--is-disabled) .placeholder {
  color: var(--color-blue-disabled) !important;
}

.select :global(.react-select__control .react-select__single-value) {
  padding: 8px 10px;
}
.select :global(.react-select__control .react-select__input-container) {
  padding-left: 10px;
}
.select :global(.react-select__control .react-select__single-value),
.select :global(.react-select__control .react-select__input-container) {
  color: var(--color-primary);
  /* textCss.p2Medium */
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}
.select :global(.react-select__control--is-disabled .react-select__single-value) {
  color: var(--color-primary-disabled);
}
.select :global(.react-select__value-container--is-multi) {
  padding: 1px;
}
.select :global(.react-select__value-container--is-multi) .placeholder {
  padding: 9px 10px;
}

.select :global(.react-select__control .react-select__multi-value) {
  color: var(--color-primary);
  background-color: var(--color-secondary);
  border-radius: 5px;
  padding: 8px 0;
  margin: 1px;
  /* textCss.p2Medium */
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.select :global(.react-select__control .react-select__multi-value__label) {
  color: var(--color-primary);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding: 0 12px;
}

.select :global(.react-select__control--is-disabled .react-select__multi-value) {
  background-color: var(--color-blue-disabled);
  padding-right: 12px;
}
.select :global(.react-select__control--is-disabled .react-select__multi-value__label) {
  color: var(--color-primary-disabled);
}
.select :global(.react-select__control .react-select__multi-value__remove) {
  background: none;
  cursor: pointer;
  padding: 0 4px 0 0;
}
.select :global(.react-select__control .react-select__multi-value__remove:hover) {
  transform: scale(1.05);
}
.select :global(.react-select__control .react-select__multi-value__remove) svg {
  width: 24px;
}
.select :global(.react-select__control--is-disabled .react-select__multi-value__remove) svg * {
  fill: var(--color-primary-disabled);
}

/* menu / dropdown */
/* WARNING: remove .select if dropdown will render outside react-select__value-container */
body :global(.react-select__menu) {
  margin-top: 12px;
  padding: 12px;
  border: none;
  border-radius: 10px;
  box-shadow: var(--shadow-diffused);
}
body :global(.react-select__menu-list) {
  padding: 0;
}

body :global(.react-select__option) {
  padding: 10px 12px;
  border-radius: 8px;
  /* textCss.p3Medium */
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--color-primary);
  cursor: pointer;
}
body :global(.react-select__option--is-selected) {
  background: none;
  /* textCss.h5 */
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
}
body :global(.react-select__option--is-focused) {
  background-color: var(--color-pale-blue);
}
body :global(.react-select__option:active) {
  background-color: var(--color-blue);
}

.noOptions {
  color: var(--color-primary-disabled);
}

.error {
  min-height: 24px;
  margin-top: 2px;
  color: var(--color-red)
}

.select.inputError :global(.react-select__control) {
  transition: 0.2s;
  border-color: var(--color-red);
}

.description {
  color: var(--color-secondary);
  text-align: right;
  word-break: normal;
  overflow-wrap: anywhere;
}
