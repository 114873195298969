.container {
    display: flex;
    flex-direction: column;
    gap: 26px;
    margin: 50px;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header>section {
    display: flex;
    align-items: center;
    gap: 12px;
}

.header>section>.info {
    color: var(--color-secondary);
}

.sideContainer {
    display: flex;
    justify-content: space-between;
    gap: 2%;
}
