.registerModal {
  margin-top: 70px;
}

.form {
  min-width: 430px;
}

.input {
  composes: input from '../Tab.module.css';
}
