.modalContainer {
    width: 100%;
}

.description {
    text-align: center;
    padding: 0 22px 22px;
}

.content {
    margin-bottom: var(--default-margin);
}

.column {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.column > * { padding-right: 7px }

.column:nth-child(1) { width: 33%; }
.column:nth-child(2) { width: 33%; }
.column:nth-child(3) { width: 33%; }

.column .expandContainer {
    cursor: pointer;
}

.column .expandContainer svg {
    width: 24px;
    height: 24px;
    transition: .5s transform;
}

.column .expandContainer .expanded {
    transform: rotate(-180deg);
}

.column .fieldTitleType {
    color: var(--color-secondary);
}

.column .select {
    width: 90%;
}

.column .sample {
    word-break: break-all;
    text-overflow: ellipsis;
    overflow: hidden;
}

.required {
    color: var(--color-red);
    vertical-align: top;
    font-size: 14px;
}
