.table * {
  border: none;
  margin: 0;
}
.table .thead {
  background-color: var(--color-pale-blue);
  border-radius: 10px;
  width: 100%;
}
.table .tbody {
  padding-top: 2px;
  width: 100%;
}

.table .row {
  position: relative;
  padding: 24px 12px;
  margin-top: -2px;
  display: flex;
}

.table .row.clickable {
  cursor: pointer;
}

.table .row:after {
  position: absolute;
  bottom: 0;
  left: 12px;
  width: calc(100% - 24px);
  height: 2px;
  background-color: var(--color-pale-blue);
  content: "";
}
.table .row:last-child:after {
  height: 0;
}

.table .row:hover {
  background-color: var(--color-pale-blue);
  border-radius: 10px;
}
.table .row:hover :global(.slick-arrow) {
  opacity: 1;
}

.table .row:hover input[type=checkbox]+label::before {
  border: 1px solid var(--color-blue);
}

.table .expandedRow {
  border-radius: 10px;
  background-color: var(--color-pale-blue);
}

.table .expandedSubRow {
  border-radius: 10px;
  background-color: var(--color-gray);
}

.table .thead .row {
  padding: 12px;
}

.table .thead .row.zeroPaddingRight, .table .row.zeroPaddingRight {
  padding-right: 0;
}

.table .cell {}
