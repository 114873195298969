.modalSubtitle {
  margin-bottom: var(--default-margin);
}

.ticketContainer {
  width: 744px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
