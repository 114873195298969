.alert {
  z-index: var(--index-alert);
  position: absolute;
  right: 50px;
  top: 25px;
  width: 340px;
  border-radius: 8px;
  box-shadow: var(--shadow-diffused);
  padding: 13px;
  display: flex;
}

.message {
  flex: 1;
  padding: 0 8px;
}

.success {
  background-color: var(--color-alert-blue);
}

.error {
  background-color: var(--color-alert-red);
}

.warning {
  background-color: var(--color-alert-yellow);
}

.severityIcon svg {
  margin-top: 2px;
  width: 20px;
}

.closeIcon svg {
  margin-top: 2px;
  width: 20px;
  cursor: pointer;
}
