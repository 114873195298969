.item {
  height: 144px;
  padding: 26px 18px;
  border: 2px solid var(--color-blue);
  border-left-width: 8px;
  border-radius: 10px;
  cursor: pointer;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.item.active,
.item .titleContainer .circle.circleActive {
  border-color: var(--color-tertiary);
}

.item .titleContainer {
  display: flex;
  align-items: center;
}

.item .titleContainer .circle {
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 10px;
  border: 1px solid var(--color-blue);
}

.item .titleContainer .circle.circleActive {
  border-width: 7px;
}

.item .description {
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}
