.input {
  margin-bottom: 10px;
  width: 430px;
}

.link, .link:hover {
  color: var(--color-secondary)
}

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.formLabel {
  display: block;
  margin-bottom: 34px;
}

.controls {
  margin-top: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
