.button {
    border: none;
    padding: 0;
    min-width: 48px;
    min-height: 48px;

    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-pale-blue);
}

.transparent:focus {
    border: none;
    background-color: var(--color-blue-alice);
}

.button.transparent:hover, .button.white:hover, .button.transparent.active {
    background-color: var(--color-blue);
}

.button.white {
    background-color: var(--color-blue-alice);
}

.button.white:focus {
    background-color: var(--color-white);
}

.button svg {
    max-width: 24px;
    max-height: 24px;
}
