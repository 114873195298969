.root {
  padding-top: 34px;
}
.ul {

}
.outlined {

}

.ul li .page:hover {
  background-color: var(--color-pale-blue);
}
.ul li .page {
  height: 48px;
  width: 48px;
  border-radius: 50%;
  color: var(--color-primary);
  font-family: 'Montserrat', serif;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
}

.ul li .icon,
.ul li .ellipsis {
  color: var(--color-primary);
}
.ul li .selected {
  background-color: var(--color-pale-blue);
  font-weight: 700;
}

