.formItem {
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
}

.formRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.formItemDesc {
  color: var(--color-secondary);
}
