.container {
  width: 100%;
  height: 100%;
}

.container .select:hover {
  opacity: 1;
}

.container .scrollArea {
  overflow: scroll;
  width: 100%;
  height: 100%;
  overscroll-behavior: none;
}
.container .scrollArea::-webkit-scrollbar {
  display: none;  /* Safari and Chrome */
}

.container .scrollArea.alignX {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.container .scrollArea.alignY {
  display: flex;
  flex-direction: row;
  align-items: center;
}
