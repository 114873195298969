.button {
    width: 44px;
    min-width: 44px;
    height: 44px;
    min-height: 44px;
}

.button svg {
    width: 24px;
    height: 24px;
}

.button[disabled] {
    cursor: not-allowed;
}

.button[disabled]:focus, .button[disabled]:hover {
    background-color: var(--color-gray);
}

.button[disabled] svg * {
    stroke: none;
}
