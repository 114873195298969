.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.container .contentWrapper {
  position: relative;
  outline: 2px solid #EAF7FD;
}

.loaderContainer {
  display: flex;
  height: 100%;
}
