.container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  margin-top: -100px;
  display: flex;
  max-width: 700px;
  flex-direction: column;
}
