.commentContainer {
  display: flex;
  justify-content: space-between;
}

.input {
  margin-bottom: 10px;
  width: 48.5%;
}

.checkbox {
  margin-bottom: 12px;
}

.fullWidth {
  width: 100%;
}

.seePricingButton {
  margin-top: 24px;
}
