.sidebar {
  position: fixed;
  right: 0;
  height: 100%;
  width: var(--width-right-sidebar);
  background: var(--gradient-sidebar);
  border-top-left-radius: 75px;
  overflow: auto;
}
.content {
  padding: 108px 64px var(--padding-bottom-page) 64px;
}
