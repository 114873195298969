.deleteButton {
    width: 48px;
    height: 48px;
    padding: 0;
    opacity: 0.75;

    display: flex;
    justify-content: center;
    align-items: center;
}

.deleteButton:hover, .deleteButton:focus {
    opacity: 0.95;
}

.deleteButton svg {
    width: 24px;
    height: 24px;
}


.modalConfirm {
    background-color: var(--color-alert-red);
}

.modalConfirm:hover, .modalConfirm:focus {
    background-color: var(--color-alert-red);
    filter: brightness(0.95);
}
