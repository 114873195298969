.container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.container .wrapper {
  display: flex;
  gap: 10px;
  justify-content: center;
  flex-direction: column;
}

.container .wrapper .description {
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  color: var(--color-blue);
}

.container .wrapper .loader {
  max-height: 48px;
}
