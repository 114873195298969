.modalContainer {
  margin-top: var(--default-margin);
}

.form {
  width: 430px;
  display: flex;
  flex-direction: column;
}

.form .input {
  width: 100%;
}

.form .addOptionSubmit {
  margin-top: var(--default-margin);
  align-self: flex-end;
}
