.root .indicator {
  width: 24px !important;
  height: 2px !important;
  background-color: var(--color-primary);
}

.root:global(.MuiTabs-vertical) .indicator {
  margin-top: 12px;
  width: 2px !important;
  height: 24px !important;
}

.root:global(.MuiTabs-root) .tabRoot {
  text-align: left;
  border-radius: 10px;
  border: 2px solid rgba(0,0,0,0);
  color: var(--color-primary);
  /* pMedium2 */
  font-family: 'Montserrat', serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding: 10px 0;
  text-transform: none;
  width: 250px;
}

.root:global(.MuiTabs-root) .tabRoot:hover {
  font-weight: 700;
}

.root:global(.MuiTabs-root) .tabRoot:not(:last-child) {
  margin-bottom: 12px;
}

.root:global(.MuiTabs-root) .tabSelected {
  font-weight: 700;
}

.root:global(.MuiTabs-vertical) .tabSelected {
  background-color: var(--color-pale-blue);
}
.root:global(.MuiTabs-vertical) .tabRoot:not(.tabSelected):hover {
  font-weight: 500;
  border: 2px solid var(--color-blue);
}
.root:global(.MuiTabs-vertical) .tabSelected:hover {
  font-weight: 700;
}

.root:global(.MuiTabs-root) .tabRoot {
  align-items: flex-start;
  justify-content: flex-start;
}
.root:global(.MuiTabs-vertical) .tabRoot {
  padding-left: 12px;
  margin-right: 12px;
}

.label {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-right: 10px;
}

.tabDisabled {
  opacity: 0.5;
}

.count {
  color: var(--color-secondary);
}

.root .tabSelected .count {
  color: var(--color-tertiary);
}
