.sideBar {
  height: 476px;
  padding-top: 78px;
  padding-bottom: var(--default-margin);
  background-color: var(--color-gray);
  position: relative;
}
.sideBar.empty {
  height: auto;
  padding: 78px var(--default-margin) var(--default-margin);
}

.sideBar .head {
  width: 108px;
  height: 108px;
  position: absolute;
  top: calc(108px / 2 * -1);
  left: calc(50% - 108px / 2);
}

.sideBar .content {
  height: 100%;
  overflow: auto;
}

.sideBar .content .title,
.sideBar .content .infoItem,
.sideBar .content .noSelected {
  text-align: center;
}

.sideBar .content .infoItem,
.sideBar .content .infoItem .infoItemNested:not(:first-child) {
  margin-top: var(--default-margin);
}

.sideBar .noSelected {
  color: var(--color-secondary);
  text-align: center;
}
