.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.name {
  color: var(--color-secondary);
  font-weight: 500;
  padding-bottom: 12px;
}

.section {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.section>header {
  font-weight: 600;
  color: var(--color-primary);
}
