.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
}

.actions {
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 4px 24px;
  border-radius: 100px;
  background-color: #EAF7FD;
  opacity: 0.5;
  transition: opacity 0.2s ease-out;
}

.actions:hover {
  opacity: 1;
}
