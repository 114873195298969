.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.name {
  color: var(--color-secondary);
  font-weight: 500;
  padding-bottom: 12px;
}
