.container {
    box-shadow: none;
    border: 2px solid var(--color-pale-blue);
    border-radius: 10px;
}

.container:before {
    content: none;
}

.expanded {
    margin: 0 !important;
}

.summaryContent {
    margin: 24px;
    gap: 12px;
}

.expandIconWrapper {
    transform: rotate(-90deg);
}

.expandIconWrapper svg {
    fill: var(--color-primary);
}

.expandIconWrapper.expandIconExpanded {
    transform: rotate(90deg);
}

.summaryIcon {
    width: 48px;
    height: 48px;
    background: var(--color-pale-blue);
    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;
}

.summaryIcon svg {
    width: 24px;
    height: 24px;
}


.summaryTitle {
    font-size: 16px;
    font-weight: bold;
    color: var(--color-primary);

    display: flex;
    justify-content: center;
    align-items: center;
}

.details {
    margin: 0 20px;
    color: var(--color-primary);
    font-weight: 500;
}
