.container {
  display: flex;
  border-radius: 10px;
  padding: 24px;
  background-color: var(--color-blue-alice);
  flex-direction: column;
  position: relative;
}

.loader {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  z-index: var(--index-modal);
}

.topControls {
  display: flex;
  justify-content: space-between;
}

.topControls .left {
  display: flex;
  align-items: center;
}
.topControls .left > * {
  margin-right: 20px;
}
.topControls .select {
  width: 200px;
}
.topControls .buttonUpload {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.topControls .left .delimiter {
  width: 2px;
  height: 40px;
  background-color: var(--color-blue);
}

.topControls .buttonUpload > svg {
  margin-right: 8px;
}

.topControls .buttonUpload:focus > svg *,
.topControls .buttonUpload:hover > svg * {
  stroke: var(--color-primary);
}

.bottomControls {
  display: flex;
  justify-content: space-between;
  min-height: 50px;
}

.bottomControls .arrowLeft {
  transform: rotate(180deg);
}

.content {
  display: flex;
  margin-top: 24px;
  margin-bottom: 12px;
  height: 400px;
}

.content .left {
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.content .right {
  width: 30%;
  display: flex;
  flex-direction: column;
}

.itemsCount {
  color: var(--color-secondary)
}
