.container {
  display: flex;
  flex-direction: column;
  gap: 36px;
}

.container h2 {
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  color: #2C3E66;
}

.container > ul {
  display: flex;
  flex-direction: column;
  gap: 36px;

  margin: 0;
  padding: 0;
}

.container > ul > li {
  display: flex;
  gap: 12px;

  font-size: 14px;
  font-weight: 500;
  color: #2C3E66;
}

.container > ul > li > svg {
  min-width: 48px;
  height: 48px;
  padding: 12px;

  background-color: #BCDEF0;
  border-radius: 10px;
}
