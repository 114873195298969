.button {
  margin: 0;
  outline: 0;
  border: 2px solid rgba(0,0,0,0);
  background: none;
  padding: 10px 24px;
  border-radius: 10px;
  min-width: 24px;
  min-height: 24px;
  transition: 0.20s;
  cursor: pointer;
}
.notDisabled:active {
  filter: brightness(0.95);
}

/* primary */
.primary {
  background-color: var(--color-blue);
}
.primary:hover {
  background-color: var(--color-secondary-hover);
}
.primary:disabled {
  border-color: rgba(0,0,0,0);
  background-color: var(--color-blue-disabled);
  color: var(--color-primary-disabled);
}

/* secondary */
.secondary {
  background-color: var(--color-pale-blue);
}
.secondary:hover {
  background-color: var(--color-blue);
}
.secondary:disabled {
  border-color: rgba(0,0,0,0);
  background: none;
  color: var(--color-primary-disabled);
}

/* tertiary */
.tertiary {}
.tertiary:hover {
  background-color: var(--color-pale-blue);
}
.tertiary:disabled {
  border-color: rgba(0,0,0,0);
  background: none;
  color: var(--color-primary-disabled);
}

/* quaternary */
.quaternary {
  border-color: var(--color-blue);
}
.quaternary:hover {
  border-color: var(--color-secondary-hover);
}
.quaternary:active {
  border-color: var(--color-secondary-hover-dark);
}
.quaternary:disabled {
  border-color: var(--color-blue-disabled);
  color: var(--color-primary-disabled);
}

.reducedHeight {
  padding-top: 8px;
  padding-bottom: 8px;
}

.square, .circle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: unset;
  width: 49px;
  height: 49px;
}

.circle {
  border-radius: 50%;
}

.button svg {
  height: 24px;
  width: 24px;
}

.button:disabled svg * {
  fill: var(--color-primary-disabled);
  stroke: var(--color-primary-disabled)
}

button:disabled {
  cursor: not-allowed;
}

.mirrorRightArrowToLeft {
  transform: scaleX(-1);
}

.button > .arrow {
  height: 12px;
  width: 18px;
}

.button > .arrow > path {
  stroke: var(--color-dark-blue);
}

.marginRight {
  margin-right: 12px;
}

.marginLeft {
  margin-left: 12px;
}
