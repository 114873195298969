.checkboxCell {
  display: flex;
  align-items: center;
}

.column > * {
  padding-right: 5px;
}
.column > * {
  height: 100%;
}
.column:nth-child(1) { width: 6%; }
.column:nth-child(2) { width: 34%; }
.column:nth-child(3) { width: 30%; }
.column:nth-child(4) { width: 30%; }
.column:nth-child(4) > * { padding-right: 0; }

.columnNames * {
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
}
.columnNames {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.displayFlex {
  display: flex;
}
