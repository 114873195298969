.container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.container button {
  width: 100%;
  min-width: 20px;
  border-radius: 0;
}

.container button:first-child {
  border-radius: 10px 0 0 10px;
}

.container button:last-child {
  border-radius: 0 10px 10px 0;
}

.container button svg {
  width: 20px;
  height: 20px;
}
