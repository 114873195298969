.stats {
  margin-top: 76px;
  display: flex;
  flex-direction: column;
}

.statRow {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  cursor: pointer;
}

.stats .statsButton {
  margin: 24px auto 0;
  width: fit-content;
}

.tabClass {
  width: 50% !important;
}
