.pageContainer {
  padding-bottom: var(--padding-bottom-page);
}

.header {
  display: flex;
  justify-content: space-between;
}

.select {
  width: 100px;
}

.right {
  display: flex;
}
.search {
  margin-right: 24px;
}

.search input {
  width: 200px;
}

.table {
  margin-top: 32px;
}

.noContent {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 400px;
}

.loader {
  min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.pagination {
  display: flex;
  justify-content: right;
}

.column > * {
  padding-right: 5px;
}
.column > * {
  height: 100%;
}
.column:nth-child(1) { width: 35% !important; }
.column:nth-child(2) { width: 50% !important; }
.column:nth-child(3) { width: 15% !important; }
.column:nth-child(3) > * { padding-right: 0; }

.columnAction {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.cellAction svg,
.columnAction svg {
  height: 24px;
  min-width: 24px;
  max-width: 24px;
}

.cellAction {
  cursor: pointer;
  background-color: var(--color-pale-blue);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
}
.cellAction:hover,
.cellAction:hover * {
  filter: brightness(0.95);
}

.columnNames * {
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
}
.columnNames {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.cellAction {
  background-color: var(--color-green);
}
.cellAction svg * {
  fill: var(--color-green-bold);
}

.locked {
  background-color: var(--color-alert-red);
}
.locked svg * {
  fill: var(--color-red);
}

.displayFlex {
  display: flex;
}

.cellCentered {
  display: flex;
  justify-content: center;
  align-items: center;
}
