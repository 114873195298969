.pdfPreviewContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pdfPreviewContainer img {
  max-width: 85px;
  max-height: 75px;
}

.column > * {
  padding-right: 5px;
}
.column * {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.column:nth-child(1) {
  width: 15%;
}
.column:nth-child(2) {
  width: 27%;
}
.column:nth-child(3) {
  width: 19%;
}
.column:nth-child(4) {
  width: 14%;
}
.column:nth-child(5) {
  width: 13%;
}
.column:nth-child(6) {
  width: 10%;
}

.column.withStatus:nth-child(1) {
  width: 15%;
}
.column.withStatus:nth-child(2) {
  width: 27%;
}
.column.withStatus:nth-child(3) {
  width: 14%;
}
.column.withStatus:nth-child(4) {
  width: 15%;
}
.column.withStatus:nth-child(5) {
  width: 16%;
}
.column.withStatus:nth-child(6) {
  width: 13%;
}

.columnHeadFilter {
  display: flex;
  justify-content: space-between;
}
.columnHeadFilter svg {
  min-width: 24px;
  max-width: 24px;
  height: 24px;
  margin-right: 24px;
}

.displayFlex {
  display: flex;
}

.centeredCell {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.columnNames {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.buttons {
  min-width: 35%;
  display: flex;
  justify-content: flex-end;
  gap: 6px
}

.row:hover .deleteBtn {
  background-color: var(--color-blue-disabled);
}

.row:hover .deleteBtn:hover {
  background-color: var(--color-blue);
}
