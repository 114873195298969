.container {
  position: relative;
}

.input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.input+.label {
  display: inline-flex;
  align-items: center;
  user-select: none;
}
.input+.label::before {
  content: '';
  display: inline-block;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  flex-grow: 0;
  border: 1px solid rgba(0,0,0,0);
  border-radius: 5px;
  background-color: var(--color-pale-blue);
  background-repeat: no-repeat;
  background-position: center center;
}

.input+.active::before {
  margin-right: 15px;
}
.active {
  color: var(--color-primary);
}

.input:hover+.label::before, .hovered.label::before {
  border: 1px solid var(--color-blue);
}

.errorForCheckbox.label::before {
  border: 1px solid var(--color-red);
}


.input:checked+.label::before {
  border-color: var(--color-primary);
  background-color: var(--color-primary);
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='7' viewBox='0 0 10 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 3L4 6L9 1' stroke='white' stroke-width='1.8' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}

.input,
.label {
  cursor: pointer;
}

.indeterminate.label::before {
  border-color: var(--color-primary);
  background-color: var(--color-primary);
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='2' viewBox='0 0 10 2' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1H9' stroke='white' stroke-width='1.8' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}

.disabled {
  filter: grayscale(0.5) opacity(0.5);
}

.errorMessage {
  min-height: 24px;
  margin-top: 2px;
  color: var(--color-red)
}
