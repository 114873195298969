.row {
  display: flex;
  width: 100%;
  min-width: 700px;
  margin-bottom: 20px;
  margin-top: 48px;
}

.cell {
  min-height: 300px;
  width: 400px;
  display: flex;
  flex-direction: column;
}

.cell:first-child {
  padding-right: 48px;
  width: 500px;
  height: 300px;
}

.title {
  margin-bottom: 24px;
}

.description {
  margin-bottom: 48px;
}

.sliderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  border-radius: 10px;
  background-color: var(--color-pale-blue);
  padding: 48px 24px;
}
