.container .resetButton {
  width: 100%;
  font-size: 16px;
  border: solid 2px var(--color-blue);
  border-radius: 10px;
}

.container .resetButton:hover {
  background-color: var(--color-blue-alice);
  border-color: var(--color-secondary-hover);
}

.container .resetButton:focus {
  background-color: var(--color-pale-blue);
  border-color: var(--color-secondary-hover-dark);
}

.container .resetButton:active {
  background-color: var(--color-blue-alice);
  border-color: var(--color-secondary-hover-dark);
}

.container .resetButton[disabled] {
  background-color: var(--color-white);
  border-color: var(--color-blue-disabled);
}

.rotateLeftIcon {
  transform: scaleX(-100%);
}

.container .button>svg {
  width: 20px;
  height: 20px;
}
