.optionContainer {
  margin-bottom: 34px;
}

.commentContainer {
  composes: commentContainer from '../TabProductSetup/TabProductSetup.module.css';
}

.negativeMargin {
  margin-bottom: -64px;
}

.input {
  composes: input from '../TabProductSetup/TabProductSetup.module.css';
}

.fullWidth {
  width: 100%;
}

.optionValueCheckbox {
  composes: paperCheckbox from '../TabProductSetup/components/PaperAndQuantity/PaperAndQuantity.module.css';
}

.seeOptionalPricingButton {
  margin-top: 14px;
}
