.controls {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: auto;
}

.counter {
    display: flex;
    justify-content: center;
    align-items: center;
}

.rightButtons {
    display: flex;
    flex-direction: row;
    gap: 12px;
}

.button {
    padding: 10px 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.button svg {
    width: 24px;
    height: 24px;
    stroke: var(--color-primary)
}
