.inputs {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
}

.inputs > * {
  margin-top: 12px;
  width: 100% !important;
}
.inputsPadding input {
  background: none;
  box-sizing: border-box;
  padding-right: 80px;
  width: 100%;

  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.inputsAdornment {
  display: flex;
  gap: 12px;
  margin-right: 12px;
  top: 14px;
  right: 0;
  position: absolute;
}

.inputsAdornment svg * {
  fill: var(--color-blue);
}
.inputsAdornment svg:first-child * {
  stroke: var(--color-blue);
}
.inputsAdornment svg:hover:first-child * {
  stroke: var(--color-primary);
}
.inputsAdornment svg:hover * {
  fill: var(--color-primary);
}
.inputsAdornment svg {
  width: 24px;
  cursor: pointer;
}

.copyIconWrapper {
  display: flex;
}
