.root {
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.wrapper {
  margin-top: -24px;
  display: flex;
  justify-content: space-between;
  width: 168px;
}
.circle {
  border-radius: 50%;
  width: 24px;
  height: 24px;
  background-color: var(--color-red);
}
.circle:nth-child(1) {
  background: linear-gradient(135deg, #4BFAE4 0%, #73DCCF 100%);
}
.circle:nth-child(2) {
  background: linear-gradient(135deg, #83D2FF 0%, #61ABD5 100%);
}
.circle:nth-child(3) {
  background: linear-gradient(135deg, #FC83D3 0%, #E25EC5 100%);
}
.circle:nth-child(4) {
  background: linear-gradient(135deg, #69B7FF 0%, #4D96DA 100%);
}
.firstLoaderCircle {
  animation: 3s ease-in infinite first;
}
.secondLoaderCircle {
  animation: 3s ease-in infinite second;
}
.thirdLoaderCircle {
  animation: 3s ease-in infinite third;
}
.fourthLoaderCircle {
  animation: 3s ease-in infinite fourth;
}

@keyframes first {
  0% {transform: translateY(0); opacity: 0;}
  10% {transform: translateY(24px); opacity: 1;}
  90% {transform: translateY(24px); opacity: 1;}
  100% {transform: translateY(48px); opacity: 0;}
}
@keyframes second {
  0% {transform: translateY(0); opacity: 0;}
  10% {transform: translateY(0); opacity: 0;}
  20% {transform: translateY(24px); opacity: 1;}
  80% {transform: translateY(24px); opacity: 1;}
  90% {transform: translateY(48px); opacity: 0;}
  100% {transform: translateY(48px); opacity: 0;}
}
@keyframes third {
  0% {transform: translateY(0); opacity: 0;}
  20% {transform: translateY(0); opacity: 0;}
  30% {transform: translateY(24px); opacity: 1;}
  70% {transform: translateY(24px); opacity: 1;}
  80% {transform: translateY(48px); opacity: 0;}
  100% {transform: translateY(48px); opacity: 0;}
}
@keyframes fourth {
  0% {transform: translateY(0); opacity: 0;}
  30% {transform: translateY(0); opacity: 0;}
  40% {transform: translateY(24px); opacity: 1;}
  60% {transform: translateY(24px); opacity: 1;}
  70% {transform: translateY(48px); opacity: 0;}
  100% {transform: translateY(48px); opacity: 0;}
}
