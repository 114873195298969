.pageTitle {
  display: flex;
  align-items: flex-start;
}

.status {
  margin-top: 0;
  margin-left: 12px;
  cursor: unset;
}
